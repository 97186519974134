import * as React from "react"
import {Link} from "gatsby"
import {useState,useEffect} from "react"
import {Helmet} from "react-helmet"
import Menu from "../components/menu"
import "../css/main.scss"
import StandardHeadContent from "../components/standardHeadContent"
import Cookies from "universal-cookie";
import Axios from "axios"

const IndexPage = () => {
    const cookies = new Cookies();
    const [lang,setLang] = useState(cookies.get("lang") ||"no");
    const [title,setTitle] = useState(cookies.get("title") || false);

    const changeLang = (lang) => {
        cookies.set("lang",lang);
        setLang(lang);
    }

    useEffect(() => {
        Axios.get("/log.php?page=index_"+lang);
    },[])

  return (
    <main>
      <Helmet>
        <title>Nina & Knut - 11. juli 2022</title>
        <StandardHeadContent/>
      </Helmet>

        <Menu changeLang={changeLang} lang={lang}/>

        <div id="duogram"></div>

        {title !== false &&
            <h1>{title.replace(/\+/g," ")}</h1>
        }

        {lang != "en" &&
            <>
                <h2>Tusen takk!</h2>
                <p>
                    Vi i forbindelse med vårt bryllup ønsker vi å takke så mye for alle de generøse gavene vi fikk, takke alle gjestene som kom i bryllupet og bidro til et kjempefint arrangement,
                    takke alle de som prøvde å komme, men på tross av flystreik, sykdom m.m. ikke fikk det til, samt takke forlovere, toastmaster og alle andre som hjalp oss og på andre måter bidro til å
                    få til denne bryllupsfeiringen!
                </p>

                <p>
                    Vi har lagt ut noen <Link to={"/bilder"}>bilder fra bryllupet på denne siden</Link> for dem som skulle være interesserte i å ta en titt.
                    Ellers vil vi veldig gjerne at de som har bilder, videoer etc fra kvelden og som ikke tidligere har delt disse med oss gjør det, f.eks. gjennom Messenger eller Whatsapp.
                </p>

                <p>
                    <strong>
                        Nina & Knut
                    </strong>
                </p>
            </>
        }
        {lang == "en" &&
            <>
                <h2>Our most sincere thanks!</h2>

                <p>
                    We would like to thank so much for all the generous gifts we received, thank all the guests that participated and created a wonderful atmosphere, thank all the guests that made an effort to come but did not make it due to
                    strike, illness, etc., and last but not least thank the maid of honour and best men, toastmaster, and everyone else that helped us to arrange this wonderful wedding.
                </p>

                <p>
                    We have posted some  <Link to={"/bilder"}>photos from the wedding on this page</Link> for those that are interested.
                    Otherwise we would appreciate it, if who anyone who has photos or videos from the evening that they have not already shared with us,
                    to share these with us on Messenger, Whatsapp or similar.
                </p>

                <p>
                    <strong>
                        Nina & Knut
                    </strong>
                </p>
            </>
        }

        {1 == 2 && lang != "en" &&
            <>
                <h2 style={{marginBlockEnd:'5px'}}>Invitasjon til bryllupsfeiring</h2>

                <div>
                    <strong>
                        11. juli 2022 kl 20:00<br/>
                        Schinias / Marathon<br/>
                        Hellas<br/>
                    </strong>
                    <br/>
                </div>

                <strong>
                    Vi inviterer til bryllupsfeiring mandag 11. juli 2022 kl 20:00
                    på <a target="_blank" href="https://g.page/galaziaaktibeach?share">Galazia Akti i Schinias</a>, på Athens østkyst og knappe 45 minutter fra flyplassen.
                </strong>
                <p>
                    Det blir også et <Link to={"/dagen-for"}>sosialt arrangement</Link> dagen før bryllupet og håper at dere har lyst og anledning
                    til å være med på det også.
                </p>

                {/*<p>
                    Svar utbedes innen <strong>1. april</strong>: <Link to={"/rsvp"}>RSVP her</Link>.
                    Om du/dere ikke har anledning til å komme, gi oss gjerne beskjed om det allerede nå.
                </p>*/}

                <strong>Flere detaljer om bryllupet og øvrig informasjon rundt reise og arrangement finner dere ved å trykke på menyen oppe til høyre på denne siden.</strong>
                <p>
                    Vi oppdaterer fortløpende disse sidene
                    også i dagene rett før bryllupet. Skulle det være noe du/dere lurer på som vi ikke
                    har nevnt setter vi pris på om dere <Link to={"/sporsmal"}>tar kontakt</Link>, så hjelper vi dere!
                </p>

                <p>
                    Som nevnt i "Save the date"-meldingen er alle som har lyst og anledning også hjertelig velkommen til å bli med
                    til <Link to={"/santorini"}>Santorini</Link> i etterkant av bryllupet.
                    Vi reiser onsdag den 13. juli blir der til den 18. juli.
                </p>

                <p>
                    Vi gleder oss veldig til å feire med dere!
                </p>

                {/*<p>
                    Rundt Athen finner man også havnene Piraeus og Rafina med gode forbindelser til de greske øyer, så det er absolutt muligheter
                    for å kombinere bryllup med videre feriereising for de som ønsker det.
                </p>
                <p>
                    Mange av dere er kanskje klar over hvor spesiell Santorini har blitt for oss, så det er kanskje ingen overraskelse at vi
                    reiser videre dit 13.-18.juli. Det vil være veldig hyggelig om de av dere som har anledning og har lyst til det
                    blir med oss dit, slik at vi kan vise frem  «våre» plasser på øya!
                </p>

                                <p>
                     Og som sagt: ikke nøl med å stille oss spørsmål dersom det er noe dere lurer på!
                </p>
                */}

                <p>
                    <strong>
                        Nina & Knut
                    </strong>
                </p>

            </>
        }
        {1 == 2 && lang == "en" &&
            <>

                <h2 style={{marginBlockEnd:'5px'}}>Invitation to wedding celebration</h2>

                <div>
                    <strong>
                        July 11th 2022 8:00 PM<br/>
                        Schinias / Marathon<br/>
                        Hellas<br/>
                    </strong>
                    <br/>
                </div>

                <strong>
                    We hereby invite you to our wedding Monday the 11th of July 2022 8:00 PM at {" "}
                    <a target="_blank" href="https://g.page/galaziaaktibeach?share">Galazia Akti in Schinias</a>,
                    at the north east coast of Athens.
                </strong>
                <p>
                    There will also be a social <Link to={"/dagen-for"}>pre-wedding event</Link> the day before the wedding
                    somewhere in the Marathon area you also are invited to.
                </p>

                {/*<p>
                    RSVP before the <strong>1st of April</strong>: <Link to={"/rsvp"}>RVSP information here</Link>.
                    If you already now know you will not be able to make it, please also let us know.
                </p>*/}

                <strong>
                    More details regarding the wedding and other information around the event can be found by pressing the menu
                    button up in the right hand corner of this page.
                </strong>

                <p>
                    We will be updating these pages as the wedding day approaches (also the last few days before), and if you have
                    any questions please don't hesitate to <Link to={"/sporsmal"}>contact us</Link> and
                    we will help.
                </p>

                <p>
                    Many of you already know how special <Link to={"/santorini"}>Santorini</Link> has become to us, so it might not surprise you that we’re going there
                    straight after the wedding, from the 13<sup>th</sup> to the 18<sup>th</sup> of July. If you have the opportunity
                    and want to join us, it would be our pleasure to get a chance to show you “our” spots on the island!
                </p>

                <p>
                    We are looking very much forward to celebrate with all of you!
                </p>

                {/*<p>
                    Rundt Athen finner man også havnene Piraeus og Rafina med gode forbindelser til de greske øyer, så det er absolutt muligheter
                    for å kombinere bryllup med videre feriereising for de som ønsker det.
                </p>
                <p>
                    Mange av dere er kanskje klar over hvor spesiell Santorini har blitt for oss, så det er kanskje ingen overraskelse at vi
                    reiser videre dit 13.-18.juli. Det vil være veldig hyggelig om de av dere som har anledning og har lyst til det
                    blir med oss dit, slik at vi kan vise frem  «våre» plasser på øya!
                </p>

                                <p>
                     Og som sagt: ikke nøl med å stille oss spørsmål dersom det er noe dere lurer på!
                </p>
                */}

                <p>
                    <strong>
                        Nina & Knut
                    </strong>
                </p>



                {/*<h1>Save the date: We're (finally) getting married</h1>

                <div>
                    <strong>
                        July 11<sup>th</sup> 2022<br/>
                        Schinias / Marathon<br/>
                        Greece<br/>
                    </strong>
                </div>

                <p>
                After years of procrastinating and social distancing, it’s finally happening! We want to invite you to our wedding
                celebration on Monday the 11<sup>th</sup> of July 2022 at <a target="_blank" href="https://g.page/galaziaaktibeach?share">Galazia Akti in
                Schinias</a>,
                located at the east coast of Athens, about 40 minutes from the airport.

                We’re also planning a social event the day before the wedding, and we hope that you all can join in on that as well.
                </p>
                <p>
                    Around Athens you’ll also find the ports of Piraeus and
                    Rafina, so attending the wedding can easily be combined with other vacation plans.
                </p>

                <p>
                    Many of you already know how special Santorini has become to us, so it might not surprise you that we’re going there
                    straight after the wedding, from the 13<sup>th</sup> to the 18<sup>th</sup> of July. If you have the opportunity
                    and want to join us, it would be our pleasure to get a chance to show you “our” spots on the island!
                </p>
                <p>
                    If you already know that you won’t be able to make it, please notify us when you can. More information and RSVP
                    will be sent out later. If you have questions don't hesitate to get in touch with either of us!
                </p>

                <p>
                    <strong>
                        Nina & Knut
                    </strong>
                </p>*/}

            </>
        }

    </main>
  )
}

export default IndexPage
